import React,{ useState,useEffect, useLayoutEffect } from 'react';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Map from '../components/Map';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import { google_map_key } from "../config/keys";
import TextField from '@material-ui/core/TextField';
import { GeoFire } from 'geofire'
import _ from 'lodash';
import * as firebase from "firebase";

import { useHistory } from 'react-router-dom';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import users2 from '../data/new_users3.json';
import logo from '../assets/img/logo9.png';
 


  
import {
    Point,
    GoogleMap
  } from "react-google-maps";



//   import { 

//     singleUserLocation
//   } from '../config/firebase';




const MapPage = () => {
    const [mylocation, setMylocation] = useState(null);
    const [mykey, setMyKey] = useState(null);

    const googleMapRefi = React.createRef();
    
    const [locations, setLocations] = useState([]);
    const [radiusLoc, setRadiusLoc] = useState(0);
    const [userLoc, setUserLoc] = useState(0);
    const [dailygross,setDailygross] = useState(0);
    const [monthlygross,setMonthlygross] = useState(0);
    const [totalgross,setTotalgross] = useState(0);
    const [pickupAddress, setPickupAddress] = useState(null);
     const [isLoaded, setIsLoaded] = useState(false);

    const [isPageLoading, setisPageLoading] = useState(false);
    const [settings,setSettings] = useState({});
      const history = useHistory();
    const myMap =  useState(0);

 



// GoogleMap.maps.event.addListener(googleMapRefi, "click", (marker) => {
//    //Can not access react state in here
//    alert(88)
// });
   // const usersdata = useSelector(state => state.usersdata);
const usersdata = []

    const getDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    return dist
  }
}

    const onDragEnd =(e) =>{
        try{
          
                         getScreenCorners(googleMapRefi);     

            // const bounds = googleMapRefi.current.getBounds();
               //     var ne = bounds.getNorthEast(); // LatLng of the north-east corner
              //  var sw = bounds.getSouthWest();
               // getScreenCorners(googleMapRefi)
                //updateData(ne.lat(), ne.lng(), sw.lat(), sw.lng())
               //  var defaultCenter = googleMapRefi.current.props.defaultCenter;
                // setMylocation({ 
                //     lat: defaultCenter.lat, 
                //     lng: defaultCenter.lng
                // })
        // this.setState({ zoom: googleMapRefi.current.getZoom(), 
        // center: { latitude: newCenter.lat(), longitude: newCenter.lng() }})
       
        }
        catch{

        }
    }

const getBondsByCenter = (zoom, lat, lng, projection) => {
  // formula to find the South west and North East points from lat,lon between 10km.
      var zf = Math.pow(2, zoom) * 2;
      var dw = window.screen.width / zf;
      var dh = window.screen.height / zf;
      debugger
     var s = new window.google.maps.LatLngBounds()
    //  var b =  new google.maps.LatLngBounds(
    //     p.fromPointToLatLng(new google.maps.Point(cpx.x - dw, cpx.y + dh)),
    //     p.fromPointToLatLng(new google.maps.Point(cpx.x + dw, cpx.y - dh)));
  debugger
        //  let lat_change = 10/111;
        //  let lon_change = Math.abs(Math.cos(lat *(Math.PI/180)));
        //  let sw_lat = lat - lat_change;
        //  let sw_lon = lng - lon_change;
        //  let ne_lat = lat + lat_change;
        //  let ne_lon = lng + lon_change;
        //      updateData(sw_lat, sw_lon, ne_lat, ne_lon)
}
      
const handleGoogleApiLoaded = () => {
 alert("map")
}

    const getScreenCorners=(googleMapRefi) =>{
   // setisPageLoading(true)
   //alert(googleMapRefi.current.getZoom())
            debugger
            var npad = 100
             const bounds = googleMapRefi.current.getBounds();

        var ne = bounds.getNorthEast(); // LatLng of the north-east corner
                var sw = bounds.getSouthWest();

                 var topRight = googleMapRefi.current.getProjection().fromLatLngToPoint(ne);
            var bottomLeft = googleMapRefi.current.getProjection().fromLatLngToPoint(sw);

 var scale = Math.pow(2, googleMapRefi.current.getZoom());

    var SWtopoint = googleMapRefi.current.getProjection().fromLatLngToPoint(sw);
    var SWpoint = new window.google.maps.Point(((SWtopoint.x - bottomLeft.x) * scale) + npad, ((SWtopoint.y - topRight.y) * scale) - npad );
    var SWworld = new window.google.maps.Point(SWpoint.x / scale + bottomLeft.x, SWpoint.y / scale + topRight.y);
    var pt1 =googleMapRefi.current.getProjection().fromPointToLatLng(SWworld);

    var NEtopoint = googleMapRefi.current.getProjection().fromLatLngToPoint(ne);
    var NEpoint = new window.google.maps.Point(((NEtopoint.x - bottomLeft.x) * scale) - npad , ((NEtopoint.y - topRight.y) * scale) + npad );
    var NEworld = new window.google.maps.Point(NEpoint.x / scale + bottomLeft.x, NEpoint.y / scale + topRight.y);
    var pt2 = googleMapRefi.current.getProjection().fromPointToLatLng(NEworld);
    updateData(pt1.lat(), pt1.lng(), pt2.lat(), pt2.lng())
    }

    const onLoaded=(e) =>{
     
        if(!isLoaded)
        {
             getScreenCorners(googleMapRefi);
             setIsLoaded(true)
        }
        
       
    }
    const onZoomChanged=(e) =>{
        debugger
        try{
            

         getScreenCorners(googleMapRefi);


                   
debugger
                
                //  var defaultCenter = googleMapRefi.current.props.defaultCenter;
                // setMylocation({ 
                //     lat: defaultCenter.lat, 
                //     lng: defaultCenter.lng
                // })
        // this.setState({ zoom: googleMapRefi.current.getZoom(), 
        // center: { latitude: newCenter.lat(), longitude: newCenter.lng() }})
       
        }
        catch( ex){
            alert(ex)

        }
       
    }

    // const onInitMap=(googleMapRef2) => {
    //     debugger
    //    // googleMapRefi = googleMapRef2
    //    const ggg = googleMapRef2.current.getBounds();
    //    if(ggg)
    //     getScreenCorners(googleMapRef2)
    // }
    const onInitMap= (googleRef) => {
        debugger
        var proj  = googleRef.current.getProjection()
        debugger
        getBondsByCenter(10,mylocation.lat,mylocation.lng ,proj)
        
    }

    const updateData = (lat1,lng1   , lat2,  lng2) => {
        debugger
        let id = userLoc;
        let radius =  radiusLoc;

  let locs = [];
  debugger
  var usersdb = users2;
//   return
    //      let dbRef = firebase
    //   .database()
    //   .ref("users" ).orderByChild("location/lat").startAt(lat1).endAt(lat2);
    // dbRef.on("value", (snap) => {
      var allUsers = [];
      let users = usersdb.users;
      if (users)
      debugger
        for (let key in users) {
        users[key].userUid = key;


debugger
        var israelLatLng = {
            lat1: 31.476747949285844,
            lng1: 34.43324305156251,
            lat2: 32.08495402515578,
            lng2: 35.91639734843751,
        }
          if ( 
            //users[key].location
             users[key].lat && users[key].lng
           && users[key].lat >= lat1     && users[key].lat<= lat2  
          && users[key].lng >= lng1 && users[key].lng<= lng2 
           && !(users[key].lat >= israelLatLng.lat1     && users[key].lat<= israelLatLng.lat2  )
          && !(users[key].lng >= israelLatLng.lng1 && users[key].lng<= israelLatLng.lng2 )
          ) 
          locs.push({
                        id:key,
                        lat:users[key].lat,
                        lng:users[key].lng,
                        drivername:users[key].name
                    });
                    
         // allUsers.push(users[key]);
        }
        //  locs.push({
        //                 id:99,
        //                 lat:lat1,
        //                 lng:lng1,
        //                 drivername:lat1+","+lng1
        //             });
        //    locs.push({
        //                 id:100,
        //                 lat:lat2,
        //                 lng:lng2,
        //                 drivername:lat2+","+lng2
        //             }); 
        if(locs.length >250)
        alert("It is advisable to look for more specific areas so that the performance will be better")    
        setLocations(locs);
        setisPageLoading(false)
       // alert(locs.length)
    //  this.setState({ helpers: allUsers.reverse() });
    // });



     
 

  

// setLocations(locs);














      if(usersdata.users ){
          alert(8)
            const drivers = usersdata.users.filter(({ usertype, pushToken, firstName, location }) => usertype === 'rider' 
            && pushToken 
            && location
            && location.lat && location.lng
           && location.lat >= lat1-1     && location.lat<= lat2 +1 
          && location.lng >= lng1-1 && location.lng<= lng2 +1
             );   
             
            let locs = [];

 locs.push({
                        id:99,
                        lat:lat1,
                        lng:lng1,
                        drivername:"one"
                    });
                     locs.push({
                        id:100,
                        lat:lat2,
                        lng:lng2,
                        drivername:"tow"
                    });
            //close to me

         
            const userLocationSearch = null
 // const id = e.target.value
    // singleUserLocation(id).once('value', userLocation => {
        //  if (userLocation.val()) {
       // let loc = userLocation.val()
         debugger
        // userLocationSearch = [loc.lat, loc.lng]
         // let distance = GeoFire.distance(riderLocation, driverLocation)
         
            for(let i=0;i<drivers.length;i++){
                if( drivers[i].driverActiveStatus ){
              
                 ///  let userLocationSearch = {}
                  // let distance = GeoFire.distance(userLocationSearch, userLocation2)
                  
                    var dis = getDistance(
            lat1, lng1,
             drivers[i].location.lat,  drivers[i].location.lng
          );
          
          drivers[i].distance = dis;
//                     if(dis/1000 < radius)
// {
                    locs.push({
                        id:i,
                        lat:drivers[i].location.lat,
                        lng:drivers[i].location.lng,
                        drivername:drivers[i].firstName + ' ' + drivers[i].lastName
                    });
//}
                }
            }


       var newdriverdis = _.sortBy( drivers, 'distance' );
        // for(let i=0;i<100;i++){
            //  locs.push({
            //             id:i,
            //             lat:newdriverdis[i].location.lat,
            //             lng:newdriverdis[i].location.lng,
            //             drivername:newdriverdis[i].firstName + ' ' + newdriverdis[i].lastName
            //         });
        // }
            debugger
        //  }
    // })
          
           


            ///////// 


         debugger
            setLocations(locs);
           // setMyKey(lat1+ lng1)
        }


    //setData({ ...data, [e.target.name]: e.target.value });
   }




    useEffect(()=>{
        if(mylocation == null){
   

            navigator.geolocation.getCurrentPosition(
               
                position => { 
                    debugger
                setMylocation({ 
                    lat: position.coords.latitude, 
                    lng: position.coords.longitude
                },
            //   getBondsByCenter(10,position.coords.latitude,position.coords.longitude)
                
                )

              // getScreenCorners()
               }
               , 
                err => {
                    
                    setMylocation(
                        { 
                    lat: 6.465422, 
                    lng: 3.406448
                }, () => {
                       alert(33)

    }); 
                setMyKey(6.465422)
                }
            )
        }
        
    },[mylocation]);

    
  useEffect(()=>{
      return
      if(!isLoaded)
      {
          //alert(66)
          var json ={
               "users" : {}
          }
               let dbRef = firebase
      .database()
      .ref("users" );
    dbRef.on("value", (snap) => {
         if (snap.val()) {
             var users = snap.val()
         for (let key in users) {
             if( users[key].location &&  users[key].location.lat &&  users[key].location.lng && users[key].firstName && users[key].lastName)
             json.users[key] = {
                 "name" : users[key].firstName.charAt(0) +"." + users[key].lastName.charAt(0)+".",
                 "lat" : users[key].location.lat,
                 "lng" : users[key].location.lng
             }
         }
        
    handleSaveToPC(json)
         debugger
         }
           
    })
          setIsLoaded(true)
      }
      
  })

  const handleSaveToPC = jsonData => {
  const fileData = JSON.stringify(jsonData);
  const blob = new Blob([fileData], {type: "text/plain"});
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'filename.json';
  link.href = url;
  link.click();
}

    //  useEffect(()=>{
         
    //     if(usersdata.users){
    //         const drivers = usersdata.users.filter(({ usertype, pushToken, firstName }) => usertype === 'rider' && pushToken  );  
    //         let locs = [];
    //         alert(drivers.length)
    //         for(let i=0;i<drivers.length-1484;i++){
    //             if( drivers[i].driverActiveStatus && drivers[i].location){
    //                 locs.push({
    //                     id:i,
    //                     lat:drivers[i].location.lat,
    //                     lng:drivers[i].location.lng,
    //                     drivername:drivers[i].firstName + ' ' + drivers[i].lastName
    //                 });
    //             }
    //         }
    //         setLocations(locs);
    //     }
    // },[usersdata.users]);


// useLayoutEffect(() => {
//    // See Note 2
//    if(googleMapRefi.current)
//     alert(8)
// })

useLayoutEffect(() => {
   
    setMyKey(44)
    //alert(99)
  });



useEffect(() => {
     if (mykey) {
    }
    
    // action on update of movies
}, [mykey]);

//     useEffect(()=>{

    


//         if(usersdata.users){
//             const drivers = usersdata.users.filter(({ usertype }) => usertype === 'rider' );  
//             let locs = [];

//             //close to me

         
// //             const userLocationSearch = null
// //   const id = "X7gjrRdDCWP7CtG1SlcjHBIkljw1"
// //     singleUserLocation(id).once('value', userLocation => {
// //           if (userLocation.val()) {
//         // let loc = userLocation.val()
         
//         // userLocationSearch = [loc.lat, loc.lng]
//          // let distance = GeoFire.distance(riderLocation, driverLocation)
//             for(let i=0;i<drivers.length;i++){
//                 if( drivers[i].driverActiveStatus && drivers[i].location){
              
               
//         //        debugger
//         //           //  let distance = GeoFire.distance(userLocationSearch, userLocation2)
//         //             var dis = getDistance(
//         //     {latitude: loc.lat, longitude: loc.lng},
//         //     {latitude: drivers[i].location.lat, longitude: drivers[i].location.lng},
//         //   );
// //                     if(dis/1000 < 10)
// // {
//                     locs.push({
//                         id:i,
//                         lat:drivers[i].location.lat,
//                         lng:drivers[i].location.lng,
//                         drivername:drivers[i].firstName + ' ' + drivers[i].lastName
//                     });
// // }
//                 }
//             }
// debugger
//         //   }
//     // })
          
           


//             ///////// 


         
//             setLocations(locs);
//         }
//     },[usersdata.users]);


    return (
         isPageLoading ? <CircularLoading/> :
        <div>
            {/* <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{languageJson.gross_earning}</Typography>
            <Grid container direction="row" spacing={2}>
                <Grid item xs>
                    <DashboardCard title={languageJson.today_text} image={require("../assets/money1.jpg")}>{ settings.symbol + ' ' + dailygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={languageJson.this_month_text} image={require("../assets/money2.jpg")}>{ settings.symbol +' ' +  monthlygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={languageJson.total} image={require("../assets/money3.jpg")}>{ settings.symbol +' ' +  totalgross}</DashboardCard>
                </Grid>
            </Grid> */}
            { mylocation?
            <Paper >
                {/* <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{languageJson.real_time_driver_section_text}</Typography> */}
                     
                     <div className="row" 
            style={{position:"absolute", 
                    top:0, 
                    top: "30px",
                    right: "25px",
                    zIndex:100, 
                   }}>
                   <a href="https://play.google.com/store/apps/details?id=com.beeplink.beep" >
                      <img src={logo} alt="" />
                      </a>
                      </div>
            <div className="row" 
            style={{position:"absolute", 
                    top:0, 
                    top: "30px",
                    left: "25px",
                    zIndex:100, 
                    backgroundColor:"white"}}>
            {/* <div className="col-lg-12 col-sm-12"> */}
          
             {/* <TextField
              variant="outlined"
              margin="normal"
              id="user"
              label="User"
              name="user"
              autoComplete="user"
              onChange={updateData}
             // value={data.firstName}
              autoFocus
            /> */}
             {/* <TextField
              variant="outlined"
              margin="normal"
              id="radius"
              label="Radius - K"
              name="radius"
              autoComplete="radius"
             onChange={updateData}
             // value={data.firstName}
              autoFocus
            /> */}
            <GoogleMapsAutoComplete 
            apiKey="AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY"
            placeholder="Location"
            onChange={ (value) => {
               var t = myMap
            
                //coords
                if(value && value.coords){
                    debugger
               setMylocation({ 
                    lat: value.coords.lat, 
                    lng: value.coords.lng
                })
                setMyKey(value.coords.lat)
                }
             //   setIsLoaded(false )
if(value && value.bonds){

                 const bounds  = value.bonds;
                    var ne = bounds.getNorthEast(); // LatLng of the north-east corner
                var sw = bounds.getSouthWest();
                 setLocations([]);
           //   updateData(ne.lat(), ne.lng(), sw.lat(), sw.lng())
           setIsLoaded(false )
           // var b = googleMapRefi.current.getProjection()
            //  getScreenCorners(googleMapRefi);
                setPickupAddress(value);
                }
                 } }
             />
            {/* </div> */}
            </div>
            <div style={{ height: `100%` ,  width: `100%` }}>
                <Map mapcenter={mylocation} locations={locations}
                key={mykey}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + google_map_key + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div style={{ height: `100vh` }} />}
                    containerElement={<div style={{ height: `100vh` }} />}
                    mapElement={<div style={{ height: `100vh` }} />}
                    googleMapRef={googleMapRefi}
                    onDragEnd = {onDragEnd}
                    onZoomChanged = {onZoomChanged}
                   onInitMap={onInitMap}
                   onLoaded={onLoaded}
                    //ref = {myMap}
                   
                  
                />
            </div>
            </Paper>
            :
            <Typography variant="h6" style={{margin:"20px 0 0 15px",color:'#FF0000'}}>{languageJson.allow_location}</Typography>
            }
        </div>
        
    )
}

export default MapPage;


//scriptLoader(['https://maps.googleapis.com/maps/api/js?key=AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY'])(MapPage);
