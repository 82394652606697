import React, { useState, useEffect } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import languageJson from "../config/language";
import AlertDialog from '../components/AlertDialog';
import { updateProfile } from '../actions/authactions';
import Switch from "react-switch";
import { Multiselect } from 'multiselect-react-dropdown';
import {items} from '../config/categories'

// import MultiSelect from "react-multi-select-component";
 


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#01D298',
    textTransform: 'none',
  },
}));

const MyProfile = () => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [commonAlert, setCommonAlert] = useState({open:false,msg:''});

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    loginType:null,
    driverActiveStatus: false
  });

  useEffect(() => {
    if (auth.info) {
      const categories = []
      if(auth.info.profile.categories && auth.info.profile.categories.length >0)
        {
          for(let i = 0; i< auth.info.profile.categories.length; i++)
          {
           
            const category = items.filter( (list) => list.id === auth.info.profile.categories[i]);
            if(category)
              categories.push(category[0])
          }
        }
      setData({
        firstName:auth.info.profile.firstName,
        lastName:auth.info.profile.lastName,
        email:auth.info.profile.email,
        driverActiveStatus:auth.info.profile.driverActiveStatus,
        categories:categories,
        mobile:auth.info.profile.mobile,
        loginType:auth.info.email?'email':null,
        uid:auth.info.uid
      });
    }
  }, [auth.info]);

  const updateData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    //map categories
    const categories = data.categories.map(function(obj) {
  return obj.id
});
const newData = JSON.parse(JSON.stringify(data))
newData.categories = categories;
    e.preventDefault();
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(data.email)){
       dispatch(updateProfile(auth.info,newData));
      setCommonAlert({open:true,msg:languageJson.profile_updated})
    }else{
      setCommonAlert({open:true,msg:languageJson.proper_email})
    }
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({open:false,msg:''})
  };

 const onSelect= (e) => {
    setData({ ...data, ["categories"]: e });
};
 
const onRemove= (e) => {
    setData({ ...data, ["categories"]: e });
};

   const handleActiveStatus = (e) => {
   
      setData({ ...data, ["driverActiveStatus"]: e });
   }


  return (
    auth.loading ? <CircularLoading /> :
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit}>
          
            <div className="row">
            <div className="col-lg-5 col-sm-12">
              <Typography component="h1" variant="h5">
              {languageJson.profile}
            </Typography>
             <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={languageJson.firstname}
              name="firstName"
              autoComplete="firstName"
              onChange={updateData}
              value={data.firstName}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={languageJson.lastname}
              name="lastName"
              autoComplete="lastName"
              onChange={updateData}
              value={data.lastName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={languageJson.email}
              name="email"
              autoComplete="email"
              onChange={updateData}
              value={data.email}
              disabled={data.loginType==='email'?true:false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile"
              label={languageJson.phone}
              name="mobile"
              autoComplete="mobile"
              onChange={updateData}
              value={data.mobile}
              disabled={data.loginType==='email'?false:true}
            />
            </div>
              <div className="col-lg-1 col-sm-12" style={{'marginBottom': '15px'}}></div>
             <div className="col-lg-6 col-sm-12">
               <Typography component="h1" variant="h5">
              Messages
            </Typography>
            <div  style={{'marginTop': '16px',
    'marginBottom': '16px'}}>
       <Typography component="h6" variant="h6" style={{ 'display': 'inline','fontSize':'17px'}}>
              Available for messages?
            </Typography>
            <div style={{marginLeft:'16px','display': 'inline', 'paddingTop': '10px'}}>
             <Switch  onColor="#01d298" onChange={handleActiveStatus} name="driverActiveStatus" id="driverActiveStatus" checked={data.driverActiveStatus} />
             </div>
           </div>

                   <div  style={{'marginTop': '32px',
    'marginBottom': '16px'}}>
            <Typography component="h6" variant="h6" >
              Categories
            </Typography>
            <div style={{ 'marginBottom':'8px'}}>
             <Typography component="h6" variant="h6" style={{ 'display': 'inline','fontSize':'17px', 'color':'gray', 'marginBottom':'10px'}}>
             Please select categories for alerts if you can offer paid service/help in these areas.
            </Typography>
            </div>
            <Multiselect
            selectedValues={data.categories}
            maxMenuHeight={250}
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove}
            selectedItemTextColor="#f673d7"
       selectedItemIconColor="#f673d7"
       avoidHighlightFirstOption = 'true'
           style={{option: { // To change input field position or margin
   select:{
      background: '#01D298'
   }
  },
  multiselectContainer: { },
    chips: { // To change css chips(Selected options)
    background: '#01D298'
  },
  optionContainer: { // To change css for option container 
  
    maxHeight:'150px',
    overflow:'auto'
  },
  searchBox: { // To change css for multiselect (Width,height,etc..)
    minHeight:'100px',
 
  },
  }}
options={items} // Options to display in the dropdown
 // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
             </div>
           
            </div>
            </div>
           
            <Button
              type="submit"
              fullWidth
              style={{'width':'105px','float':'right'}}
              variant="contained"
              color="success"
              className={classes.submit}
            >
              {languageJson.submit}
            </Button>
          </form>
        </div>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      </Container>
  );
};

export default MyProfile;