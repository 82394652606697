import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import LandingPage from "views/LandingPage.js";
import LoginPage from "views/LoginPage.js";
import MapPage from "views/MapPage.js";
import DeleteAccount from "components/DeleteAccount.js";
import PrivacyPolicy from "components/PrivacyPolicy.js";
import AboutUs from "views/AboutUs";
import AuthLoading from './components/AuthLoading';
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import MyProfile from './views/MyProfile';
import BookingHistory from './views/BookingHistory';
import { fetchUser}  from "./actions/authactions";
import { fetchCarTypes } from "./actions/cartypeactions";
import { fetchCategoryTypes } from "./actions/categoryactions";
import { fetchUsers } from "./actions/usersactions";
import { useHistory } from "react-router-dom";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import './assets/css/landing.css';
import { colors } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
const THEME = createMuiTheme({
   typography: {
    "fontFamily": "ProximaNova",
    "textTransform": 'none',
   
   },
    palette: {
       action: {
            // selected: '#E7A615',
            // hover: '#01D298',
            // disabled: '#9B9B9B'
        },
        primary: {
      // light: will be calculated from palette.primary.main,
      main: '#01D298',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
       success: {
      // light: will be calculated from palette.primary.main,
      main: '#01D298',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
      secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#01D298',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
      }
});

var hist = createBrowserHistory();

function App() {
 
  store.dispatch(fetchUser());
  store.dispatch(fetchCarTypes());
  store.dispatch(fetchCategoryTypes());
  //store.dispatch(fetchUsers())
  
  return (
  <MuiThemeProvider theme={THEME}>
  <Provider store={store}>
    <AuthLoading>
      <Router history={hist}>
        <Switch>
          <ProtectedRoute exact component={BookingHistory} path="/request-history"/>
          <ProtectedRoute exact component={MyProfile} path="/my-profile"/>
          <Route path="/login" component={LoginPage} />
          <Route path="/map" component={LoginPage} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/privacyPolicy" component={PrivacyPolicy} />
          <Route path="/DeleteAccount" component={DeleteAccount} />
          <Route path="/map-page" component={MapPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
      </AuthLoading>
    </Provider>
  </MuiThemeProvider>
  );
}

export default App;