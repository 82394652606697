import React,{ useState,useLayoutEffect, useEffect } from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps";
  import scriptLoader from 'react-async-script-loader';
  import {  GoogleApiWrapper } from "google-maps-react";

 

const mapStyle = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#8cb1b9"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#FFFFFF"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#8cb1b9"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#01d298"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "-22"
            },
            {
                "lightness": "30"
            },
            {
                "gamma": "2.26"
            },
            {
                "visibility": "on"
            },
            {
                "color": "#d5d8db"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "color": "#fad2b9"
            }
        ]
    }
]


const defaultMapOptions = {
  fullscreenControl: false,
};



const Map = withScriptjs(withGoogleMap(props =>
{
   const [mapcenter] = useState(props.mapcenter);


 useEffect(()=>{
//  var ref = window.document.getElementsByTagName("script")[0];
//     var script = window.document.createElement("script");
//     script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY&libraries=geometry,drawing,places&callback=initMap';
//     script.async = true;
//     ref.parentNode.insertBefore(script, ref);
 })
// useEffect(() => {
//     window.initMap = () => {
//       new google.maps.Map(document.getElementById('map'), {
//         center: { lat: -34.397, lng: 150.644 },
//         zoom: 8,
//       });
//     };
//   }, []);


    //    useLayoutEffect(()=>{
    //      if(props.googleMapRef){
    //       debugger
    //       // if(props.googleMapRef.current.getBounds()){
    //       //   alert("bonds")
    //       // }
    //       alert(props.mapcenter.lat)
    //       // props.onInitMap(props.googleMapRef);  
    //      }
    //  },[props.googleMapRef]);

  return(
  <GoogleMap
    defaultZoom={10}
     bootstrapURLKeys={{ key: 'AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY' }}
    ref={props.googleMapRef}
   onDragEnd={props.onDragEnd}
   
  onZoomChanged={props.onZoomChanged}
    onTilesLoaded={props.onLoaded}
    defaultCenter={props.mapcenter }
    initialCenter={{ lat: 6.465422, 
                    lng: 3.406448 }}
    yesIWantToUseGoogleMapApiInternals={true}
   onGoogleApiLoaded={({ map }) => {
        alert(22)
      }}
    
       options={{
        styles: mapStyle,
        mapTypeControl: false,  
        streetViewControl: false,
        fullscreenControl:false,
        minZoom:5
      }}
  >
    {props.locations.map(marker => (
        <Marker
            position={{ lat: marker.lat, lng: marker.lng }}
            key={marker.id}
            options={{icon: require('../assets/img/mmm.png'), size: { width: 26, height: 26 }}}
        >
          <InfoWindow>
            <div>{marker.drivername}</div>
          </InfoWindow>
        </Marker>
    ))}
  </GoogleMap>
  )
 }
));

//export default scriptLoader(['https://maps.googleapis.com/maps/api/js?key=AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY'])(Map);
 //export default Map;
 export default GoogleApiWrapper({
  apiKey: "AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY"
})(Map);
