import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AlarmIcon from '@material-ui/icons/Alarm';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";


import img11 from '../../assets/img/11.png';
import img12 from '../../assets/img/12.png';
import img13 from '../../assets/img/13.png';
import img14 from '../../assets/img/14.png';
import img15 from '../../assets/img/15.png';
import banner2  from '../../assets/img/banner2.png';
import howitworks from '../../assets/img/HowIt.png';
import googleImg from '../../assets/img/google.png';
import appleImg from '../../assets/img/apple.png';
import logo from '../../assets/img/logo.png';
import ulta from '../../assets/img/ulta.png';
import two from '../../assets/img/two.png';


const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div>
     <section id="banner" style={{backgroundImage: `url(${banner2})`}}>
          {/* <div class="banner-image"><img src="../assets/img/three.png" alt=""></div> */}
          <div className="angle"><img src={img11} alt="" /></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner-contents">
                  {/* <img  style="width: 77px;
                        height: 77px;" src="../assets/img/beeplink_Providing_Android.png?v=2.0" alt=""> */}
                  <img src={logo} alt="" />
                  <p style={{fontSize: '25px', fontFamily: 'ProximaNova', 'line-height': '1.2em'}}>
                    {/*        Social network for getting express service from people around you, in urgent times. */}
                    {/* Connects you to people in your vicinity for urgent help or prompt service regarding any issue */}
                    Get connected to friendly people in your immediate vicinity for urgent help or prompt service regarding any issue 
                  </p>
                  <p />
                  <div className="store-image">
                    <ul>
                      <li><a href="https://play.google.com/store/apps/details?id=com.beeplink.beep"><img src={googleImg} alt="" /></a></li>
                      <li><a href=" https://apps.apple.com/us/app/beeplink/id1509910636"><img src={appleImg} alt="" /></a></li>
                    </ul>
                  </div>
                  <div className="angle-down">
                    <div className="angle-down-inner">
                      <a href="#receiving"><i className="fa fa-chevron-down" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{paddingTop: '250px'}}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>What Can I Get a Solution For?
                  </h2>
                  {/* <p>At Beeplink you will find a variety of professionals to help you with any problem at home, in the public space, and in any other field (health, community, work, study and more)</p> */}
                  <p>Anything at all! If you need a babysitter, a professional consultation, help moving furniture, or perhaps you’ve left something behind and need someone to pick it up for you, you can send out a request. In all kinds of difficult situations, just send a request and see who is available to handle it.
                  </p> <p>   Services are provided by individuals using the Beeplink app to assist other users in their spare time, doing good things in their community, and building a better world for everyone.
                  </p>
                  <div><img src={img14} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="receiving">
          <div className="topleft"><img src={img12} alt="" /></div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="receiving-image">
                  <img src={two} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="receiving-contents">
                  {/* <img  style="width: 250px;
                        height: 177px;" src="../assets/img/beeplink_Providing.png?v=2.0" alt=""> */}
                  <h2>Sending a Request</h2>
                  {/* <h4>For express service with any issue, <strong>24 hours</strong> a day.</h4> */}
                  <p>When you need assistance or a good turn quickly, it is just the press of a button away. Send a request by describing the situation, choosing a suitable time, and set the price you’re willing to pay. 
                    </p> <p>
                  It’s fully location-based, meaning the message will be sent to the people who are nearest to your geographical location to ensure a fast response. People who are capable of handling your request are prioritized.
                  </p>
                  <div className="store-image">
                    <ul>
                      <li><a href="https://play.google.com/store/apps/details?id=com.beeplink.beep"><img src={googleImg} alt="" /></a></li>
                      <li><a href=" https://apps.apple.com/us/app/beeplink/id1509910636"><img src={appleImg} alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="angle"><img src={img13} alt="" /></div>
        </section>
        <section id="providing">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="receiving-contents">
                  {/* <img style="width: 250px;
                        height: 177px;" src="../assets/img/beeplink_Providing.png?v=2.0" alt=""> */}
                  <h2>Receiving a Request </h2>
                  <p>When a request is sent, messages will be delivered to people based on their areas of interest. After completing the task, payment for your work will appear in the "App Wallet". You are free to withdraw the funds directly to your Paypal account at any time. 
                  </p>
                  <p>If you like helping people and want to make a difference in your local area, Beeplink makes it possible to help people in your vicinity more easily than ever before.
                  </p>
                  <div className="store-image">
                    <ul>
                      <li><a href="https://play.google.com/store/apps/details?id=com.beeplink.beep"><img src={googleImg} alt="" /></a></li>
                      <li><a href="https://apps.apple.com/us/app/beeplink/id1509910636"><img src={appleImg} alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="receiving-image">
                  <img src={ulta} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="dd">
          <section id="work">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2>How It Works</h2>
                    <h4>Easily find quality matches</h4>
                    {/* <p>At Beeplink you will find a variety of professionals to help you with any problem at home, in the public space, and in any other field (health, community, work, study and more)</p> */}
                    <p>The app works in a similar way to a pager device, receiving alerts in real-time, based on the location and settings chosen and personalized by the user. These options include areas of practice, areas of interest, skills and talents, etc.
                    </p> <p>   As soon as someone needs your assistance, you will receive a message and hear a "beep" - that’s your signal to spring into action!
                    </p>
                    <div className="topleft"><img src="../assets/img/HowIt.png" alt="" /></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="angle"><img src={img15} alt="" /></div>
          </section>
          <section id="request">
            <div className="container">
              <div className="row" style={{paddingBottom: '150px'}}>
                <div className="col-12">
                  <img style={{height: '100%', width: '100%'}} src={howitworks} alt="" />
                </div>
              </div>
            </div>
          </section>
          </section>
      </div>
  );
}
