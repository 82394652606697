import { categoryTypesRef } from "../config/firebase";
import {
  FETCH_CATEGORY_TYPES,
  FETCH_CATEGORY_TYPES_SUCCESS,
  FETCH_CATEGORY_TYPES_FAILED,
  EDIT_CATEGORY_TYPE
} from "./types";
import languageJson from '../config/language';

export const fetchCategoryTypes = () => dispatch => {
  dispatch({
    type: FETCH_CATEGORY_TYPES,
    payload: null
  });
  categoryTypesRef.on("value", snapshot => {
    if (snapshot.val()) {
      dispatch({
        type: FETCH_CATEGORY_TYPES_SUCCESS,
        payload: snapshot.val()
      });
    } else {
      dispatch({
        type: FETCH_CATEGORY_TYPES_FAILED,
        payload: languageJson.no_cars
      });
    }
  });
};

export const editCategoryType = (categoryTypes, method) => dispatch => {
  dispatch({
    type: EDIT_CATEGORY_TYPE,
    payload: method
  });
  categoryTypesRef.set(categoryTypes);
}