

// export const FirebaseConfig = {
//   apiKey: "AIzaSyDl7cRphmSYC1I0NS9Xn8625W0qyMBL4OE",
//     authDomain: "beeplink-test.firebaseapp.com",
//     databaseURL: "https://beeplink-test.firebaseio.com",
//     projectId: "beeplink-test",
//     storageBucket: "beeplink-test.appspot.com",
//     messagingSenderId: "530423287614",
//     appId: "1:530423287614:web:b3e477c56271b63a09666f",
//     measurementId: "G-50WQG5YFS5"
// };


export const FirebaseConfig = {
    apiKey: "AIzaSyALUMteZ5t1FZutXyaQ9Q8St4WHW8GWZJ8",
    authDomain: "beep-452f3.firebaseapp.com",
    databaseURL: "https://beep-452f3.firebaseio.com",
    projectId: "beep-452f3",
    storageBucket: "beep-452f3.appspot.com",
    messagingSenderId: "1089171421226",
    appId: "1:1089171421226:web:bdf7262c1a60550c5ceb53",
    measurementId: "G-J4TRJ9MKCB"
  
};



export const google_map_key = 'AIzaSyBmKdGO0dr2-NXvUErDWmdo2vy6idYcEcY';
