/*eslint-disable*/
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Info, AccountBox, House } from "@material-ui/icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector } from "react-redux";
import  languageJson  from "../../config/language";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(()=>{
    if(auth.info){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.info]);


  return (
    <List className={classes.list}>
       <ListItem className={classes.listItem}>
        {/* <Button
          href="/"
          color="transparent"
          className={classes.navLink}
            style={{
             textTransform: 'none',
             display: 'block',
    color: '#000',
    fontSize: '17px',
    fontWeight: 'bold',
    marginRight: '25px',
    position: 'relative',
    transition: '.3s',
    paddingBottom: '10px',
            }}
        > */}
          {/* <House className={classes.icons} /> */}
          {/* {languageJson.home} */}
         {/* </Button> */}
      </ListItem> 
      {/* <ListItem className={classes.listItem}>
        <Button
         style={{
             fontFamily: 'ProximaNova-bold',
             textTransform: 'none',
             display: 'block',
    color: '#000',
    fontSize: '17px',
    fontWeight: 'bold',
    marginRight: '25px',
    position: 'relative',
    transition: '.3s',
    paddingBottom: '10px',
            }}
          href="/request-history"
          color="transparent"
          className={classes.navLink}
        > */}
          {/* {loggedIn?
            <AccountBox className={classes.icons} /> 
            : 
            <AccountBox className={classes.icons} />  
          }          */}
         
          {/* {loggedIn?
            languageJson.myaccount
            : 
           languageJson.login_signup
          }
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button
          href="/about-us"
          color="transparent"
          className={classes.navLink}
        >
          <Info className={classes.icons} />{languageJson.about_us}
        </Button>
      </ListItem> */}
        <ListItem className={classes.listItem}>
        <Button
          href="/privacyPolicy"
          color="transparent"
          
          className={classes.navLink}
            style={{
             textTransform: 'none',
             display: 'block',
    color: '#000',
    fontSize: '17px',
    fontWeight: 'bold',
    marginRight: '25px',
    position: 'relative',
    transition: '.3s',
    paddingBottom: '10px',
            }}
        > Privacy Policy
      </Button> 
         
      </ListItem> 
      <ListItem className={classes.listItem}>
     <div className="collapse-nav" id="desktop-menu"  style={{paddingTop:'10px'}}>
      
                <ul className="social-menu">
                        <li><a href="https://www.facebook.com/Beeplink-111792520524308/app/116943498446376/"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="https://twitter.com/Beeplink1"><i className="fab fa-twitter" /></a></li>
                        <li><a href="https://www.youtube.com/embed/5CLiQyw6-_Q?autoplay=1" target="_BLANK"><i className="fab fa-youtube" /></a></li>
                      </ul>
                      </div>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/exicube"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  );
}
