export const items = [
    {
          name: "Animals and pets",
          id: 1,
        },{
          name: "Architecture",
          id: 2,
        },{
          name: "Art",
          id: 3,
        },{
          name: "Cars and motorcycles",
          id: 4,
        },{
          name: "Celebrities",
          id: 5,
        },{
          name: "DIY and crafts",
          id: 6,
        },{
          name: "Design",
          id: 7,
        },{
          name: "Education",
          id: 8,
        },{
          name: "Entertainment",
          id: 9,
        },{
          name: "Food and drink",
          id: 10,
        },{
          name: "Gardening",
          id: 11,
        },{
          name: "Geek",
          id: 12,
        },
        {
          name: "Hair and beauty",
          id: 13,
        },
        {
          name: "Health and fitness",
          id: 14,
        },
        {
          name: "History",
          id: 15,
        },
        {
          name: "Holidays and events",
          id: 16,
        },
        {
          name: "Home decor",
          id: 17,
        },
        {
          name: "Humor",
          id: 18,
        },
        {
          name: "Illustrations and posters",
          id: 19,
        },
        {
          name: "Kids and parenting",
          id: 20,
        },
        {
          name: "Men’s fashion",
          id: 21,
        },
        {
          name: "Photography",
          id: 22,
        },
        {
          name: "Products",
          id: 23,
        },
        {
          name: "Quotes",
          id: 24,
        },
        {
          name: "Science and nature",
          id: 25,
        }, {
          name: "Sports",
          id: 26,
        }, {
          name: "Technology",
          id: 27,
        }, {
          name: "Travel",
          id: 28,
        }, {
          name: "Weddings",
          id: 29,
        }, {
          name: "Women’s fashion",
          id: 30
        }
    ]
 
   
  
  

