export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const USER_NOT_REGISTERED = "USER_NOT_REGISTERED";
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCESSS = "USER_REGISTER_SUCESSS";
export const USER_REGISTER_FAILED = "USER_REGISTER_FAILED";
export const USER_EMAIL_SIGNUP = "USER_EMAIL_SIGNUP";
export const USER_EMAIL_SIGNUP_FAILED = "USER_EMAIL_SIGNUP_FAILED";

export const FETCH_CAR_TYPES = "FETCH_CAR_TYPES";
export const FETCH_CAR_TYPES_SUCCESS = "FETCH_CAR_TYPES_SUCCESS";
export const FETCH_CAR_TYPES_FAILED = "FETCH_CAR_TYPES_FAILED";
export const EDIT_CAR_TYPE = "EDIT_CAR_TYPE";

export const FETCH_CATEGORY_TYPES = "FETCH_CATEGORY_TYPES";
export const FETCH_CATEGORY_TYPES_SUCCESS = "FETCH_CATEGORY_TYPES_SUCCESS";
export const FETCH_CATEGORY_TYPES_FAILED = "FETCH_CATEGORY_TYPES_FAILED";
export const EDIT_CATEGORY_TYPE = "EDIT_CATEGORY_TYPE";


export const FETCH_BOOKINGS= "FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_FAILED = "FETCH_BOOKINGS_FAILED";
export const CANCEL_BOOKING= "CANCEL_BOOKING";
export const EDIT_BOOKING= "EDIT_BOOKING";

export const FETCH_ESTIMATE= "FETCH_ESTIMATE";
export const FETCH_ESTIMATE_SUCCESS= "FETCH_ESTIMATE_SUCCESS";
export const FETCH_ESTIMATE_FAILED= "FETCH_ESTIMATE_FAILED";
export const CLEAR_ESTIMATE= "CLEAR_ESTIMATE";

export const CONFIRM_BOOKING = "CONFIRM_BOOKING";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_FAILED = "CONFIRM_BOOKING_FAILED";
export const CLEAR_BOOKING = "CLEAR_BOOKING";

export const FETCH_CANCEL_REASONS = "FETCH_CANCEL_REASONS";
export const FETCH_CANCEL_REASONS_SUCCESS = "FETCH_CANCEL_REASONS_SUCCESS";
export const FETCH_CANCEL_REASONS_FAILED = "FETCH_CANCEL_REASONS_FAILED";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED";

export const FETCH_ALL_USERS= "FETCH_ALL_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAILED = "FETCH_ALL_USERS_FAILED";