import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import 'bootstrap/dist/css/bootstrap.min.css';

//images

import logo from '../assets/img/logo.png';
import logoFooter from '../assets/img/f-logo.png';

const dashboardRoutes = [];

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  
}));

export default function AboutUs(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
     <div>
        {/* <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="Connects you to people in your vicinity." />
        <meta httpEquiv="cache-control" content="no-cache, must-revalidate, post-check=0, pre-check=0" />
        <meta httpEquiv="cache-control" content="max-age=0" />
        <meta httpEquiv="expires" content={0} />
        <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
        <meta httpEquiv="pragma" content="no-cache" />
        <title>Beeplink</title>
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/font-awesome.min.css" />
        <link rel="stylesheet" href="assets/css/all.css" />
        <link rel="stylesheet" href="style.css" />
        <link rel="icon" href="../assets/img/beeplink_favicon_32px.png" /> */}
        {/* Global site tag (gtag.js) - Google Analytics */}
        {/* Add Your HTML here */}
        <header>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header-inner">
                  <div className="logo">
                    <a href="/"><img src={logo} alt="" /></a>
                  </div>
                  <div className="main-menu-wrapper">
                    <div className="nav-header">
                      <div className="hamburger" />
                    </div>
                    <div className="collapse-nav" id="desktop-menu">
                      <ul className="menu">
                        <li><a href="#receiving">Sending a Request</a></li>
                        <li><a href="#providing">Receiving a Request</a></li>
                        <li><a href="#work">How It Works</a></li>
                      </ul>
                      <ul className="social-menu">
                        <li><a href="https://www.facebook.com/Beeplink-111792520524308/app/116943498446376/"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="https://twitter.com/Beeplink1"><i className="fab fa-twitter" /></a></li>
                        <li><a href="https://www.youtube.com/embed/5CLiQyw6-_Q?autoplay=1" target="_BLANK"><i className="fab fa-youtube" /></a></li>
                      </ul>
                    </div>
                    <div className="collapse-nav" id="mobile-menu">
                      <ul className="menu">
                        <li><a href="#receiving">Sending a Request</a></li>
                        <li><a href="#providing">Receiving a Request</a></li>
                        <li><a href="#work">How It Works</a></li>
                      </ul>
                      <ul className="social-menu">
                        <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                        <li><a href="#"><i className="fab fa-twitter" /></a></li>
                        <li><a href="#"><i className="fab fa-youtube" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
       
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-logo">
                    <a href="/"><img src={logoFooter} alt="" /></a>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-menu">
                    <ul>
                      <li><a href="#">Get in touch</a></li>
                      <li><a href="#"><i className="far fa-envelope" /></a></li>
                      <li><a href="mailto:info@beeplink.com">info@beeplink.com</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/* TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries */}
          {/* Initialize Firebase */}
       </div>
  );
}
