import React from "react";
import DeleteAccountStep1 from '../assets/img/deleteAccountSep1.jpg';
import DeleteAccountStep2 from '../assets/img/deleteAccountStep2.jpg';



export default class DeleteAccount extends React.Component {
  constructor (props) {
    super(props)

  }


  render () {
    return (
 <div>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="description" content="Realtime" />
        <title>Get express service\help with any issue based on your location</title>
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i" rel="stylesheet" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xs-12 col-sm-12" styles={{marginTop:'35px'}}>
              <h3 style={{paddingBottom:40}}>Guide to delete an account</h3>
              <h5>Click on Menu =>  Profile Settings</h5>
               <img src={DeleteAccountStep1} style={{width:300, height:600, paddingBottom:70}}></img>
              <h5>Click on "Delete Account"</h5>
              <img src={DeleteAccountStep2} style={{width:300, height:600, paddingBottom:70}}></img>
              <h5> Your details will be completely deleted from the system    </h5>        </div>	
          </div>	
        </div>       
        {/*-- Content End --*/}
        {/* jQuery (necessary for Bootstrap's JavaScript plugins) */}
        {/* Include all compiled plugins (below), or include individual files as needed */}
      </div>)}
}


